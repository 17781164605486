import React from "react"
import { graphql } from "gatsby"
import GatsImg from "gatsby-image"
import Headermenu from "../containers/RealestatePage1/HeaderMenuBS"
import Footer from "../containers/RealestatePage1/Footer"
import GlobalStyle from "../containers/RealestatePage1/Common/global-styles"
import { Container } from "react-bootstrap"
import Fonts from "../containers/RealestatePage1/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"
import {
  contentWrapper,
  content,
  image,
} from "../containers/RealestatePage1/Common/markdownStyle.module.css"

function CustomHome({ data }) {
  console.log(data)
  const { html } = data.markdownRemark
  const { img, title, subTitle } = data.markdownRemark.frontmatter

  return (
    <>
      <Fonts />
      <GlobalStyle />
      <main>
        <SEO
          title="Custom Home"
          description="Let us help you build your dream home"
        />
        <Headermenu home={false} />

        <div className={contentWrapper}>
          <Container>
            <div className={content}>
              <article>
                <h2>{subTitle}</h2>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <GatsImg fluid={img.childImageSharp.fluid} className={image} />
              </article>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}
export const query = graphql`
  query CustomQuery {
    markdownRemark(frontmatter: { title: { eq: "custom homes" } }) {
      id
      html
      frontmatter {
        img {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subTitle
      }
    }
  }
`

export default CustomHome
